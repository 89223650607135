/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    IncidentStatisticHistory,
    ResponseReason,
} from './';

/**
 * Response with incidents statistic
 * @export
 * @interface ResponseStatisticHistoryBody
 */
export interface ResponseStatisticHistoryBody {
    /**
     * @type {IncidentStatisticHistory}
     * @memberof ResponseStatisticHistoryBody
     */
    data?: IncidentStatisticHistory;
    /**
     * @type {string}
     * @memberof ResponseStatisticHistoryBody
     */
    message?: string;
    /**
     * @type {ResponseReason}
     * @memberof ResponseStatisticHistoryBody
     */
    reason?: ResponseReason;
}
