/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Incident reason classification
 * @export
 * @enum {string}
 */
export enum IncidentReasonEnum {
    Missing = 'missing',
    HiddenProblem = 'hidden_problem',
    NotConfirmed = 'not_confirmed',
    NotSpecified = 'not_specified',
    BrokenCasing = 'broken_casing',
    NoReason = 'no_reason'
}

