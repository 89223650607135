/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Incident state for unexpected reset error
 * @export
 * @interface UnexpectedResetErrorState
 */
export interface UnexpectedResetErrorState {
    /**
     * @type {Date}
     * @memberof UnexpectedResetErrorState
     */
    error_datetime: Date;
    /**
     * @type {Date}
     * @memberof UnexpectedResetErrorState
     */
    second_error_datetime: Date;
    /**
     * @type {string}
     * @memberof UnexpectedResetErrorState
     */
    second_trace_id: string;
    /**
     * @type {string}
     * @memberof UnexpectedResetErrorState
     */
    trace_id?: string;
}
