import { Injectable } from '@angular/core';
import { PositionedDeviceData, Position } from 'src/app/model';
import { DmStoreService } from 'src/app/store/dm-store.service';
import { CustomMarkers, LatLon } from 'src/app/model/maps';
import { GoogleMap, LatLngBounds, MapType, Marker, Polygon, Polyline } from '@capacitor/google-maps';
import { LatLng } from 'src/app/types/googlemap.capacitor.types';
import { CreateMapArgs } from '@capacitor/google-maps/dist/typings/implementation';
import { GoogleMapConfig } from '@capacitor/google-maps/dist/typings/definitions';
import { PositionedDeviceDataWithSilents } from 'src/app/model/placedDeviceDataWithSilents';
import { LocaLstorageService } from 'src/app/store/localstorage.service';
import { MarkersType } from 'src/app/types/markers.types';
import { environment } from 'src/environments/environment';

const EMPTY_POSITION = 'Empty Position';

@Injectable({
  providedIn: 'root',
})
export class NativeMapsService {
  private readonly dmStore: DmStoreService;
  private readonly localStorage: LocaLstorageService;
  public map: GoogleMap;
  private readonly devicesCluster: Map<string, string> = new Map();
  private readonly positionsCluster: Map<string, string> = new Map();
  private readonly customCluster: Map<string, string> = new Map();
  private polylineId?: string;
  private dotId?: string;

  private config: GoogleMapConfig = {
    center: { lat: 0, lng: 0 },
    zoom: 7,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    panControl: false,
    scaleControl: true,
    tilt: 0,
  };

  constructor(store: DmStoreService, localStorage: LocaLstorageService) {
    this.dmStore = store;
    this.localStorage = localStorage;
  }

  public async setOptionsForAccurate(isAccurating: boolean) {
    const offset = {
      top: 60,
      bottom: 60,
      left: 0,
      right: 0,
    };
    const init = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };

    this.map.setPadding(isAccurating ? offset : init);
  }

  public dragEnd = (callback: () => void) => this.map.setOnCameraIdleListener(callback);

  public async init(center: LatLng, element: HTMLElement, apiKey: string) {
    this.config.center = center;

    const mapOptions: CreateMapArgs = {
      id: 'map',
      element,
      config: this.config,
      apiKey,
      forceCreate: true,
    };

    try {
      this.map = await GoogleMap.create(mapOptions);
    } catch (error) {
      console.error(error);
    }

    this.map.setOnMarkerClickListener(({ title, markerId }) => {
      if (title === EMPTY_POSITION) {
        const [positionId] = [...this.positionsCluster.entries()].find(([_id, mapMarkerId]) => mapMarkerId === markerId) || [];

        if (positionId) {
          this.dmStore.selectPosition(positionId);
        }
      }
      this.dmStore.selectDevice(title);
    });

    this.map.setOnCameraIdleListener(({ zoom, latitude, longitude }) => {
      this.config.center = { lat: latitude, lng: longitude };
      this.config.zoom = zoom;
    });
  }

  public async setType(mapType: MapType) {
    this.config.mapTypeId = mapType;
    const currentType = await this.map.getMapType();

    if (mapType === currentType) {
      return;
    }

    await this.map.setMapType(mapType === MapType.Normal ? 'roadmap' as MapType : mapType);
  }

  private async removeDeviceById(id: string): Promise<void> {
    const mapMarkerId = this.devicesCluster.get(id);

    if (mapMarkerId) {
      this.devicesCluster.delete(id);
      await this.map.removeMarker(mapMarkerId);
    }

    return Promise.resolve();
  }

  private async removePositionById(id: string): Promise<void> {
    const mapMarkerId = this.positionsCluster.get(id);

    if (mapMarkerId) {
      this.positionsCluster.delete(id);
      await this.map.removeMarker(mapMarkerId);
    }

    return Promise.resolve();
  }

  public getZoom(): number {
    return this.config.zoom;
  }

  public async getVisible(): Promise<LatLngBounds> {
    return await this.map.getMapBounds();
  }

  public async bound(markers: LatLon[]) {
    const bounds: LatLngBounds = new LatLngBounds({
      southwest: { lat: markers[0].lat, lng: markers[0].lon },
      center: { lat: markers[0].lat, lng: markers[0].lon },
      northeast: { lat: markers[0].lat, lng: markers[0].lon },
    });

    for (const marker of markers) {
      if (marker.lat && marker.lon) {
        const latLng: LatLng = { lat: marker.lat, lng: marker.lon };
        await bounds.extend(latLng);
      }
    }

    await this.map.fitBounds(bounds);
  }

  private async clearCluster(cluster: Map<string, string>): Promise<void> {
    const ids = [...cluster.values()];

    if (!ids.length) {
      return;
    }

    cluster.clear();

    await this.map.removeMarkers(ids);
  }

  public clearDevicesMarkers(): void {
    this.clearCluster(this.devicesCluster);
  }

  public clearPositionsMarkers(): void {
    this.clearCluster(this.positionsCluster);
  }

  public async deleteUnnecessaryMarkers(devices: PositionedDeviceData[], positions: Position[]): Promise<boolean> {
    for (const deviceId of this.devicesCluster.keys()) {
      if (!devices.find(dv => dv.device_id === deviceId)) {
        await this.removeDeviceById(deviceId);
      }
    }

    for (const placeId of this.positionsCluster.keys()) {
      if (!positions.find(pl => `${pl.id}` === placeId)) {
        await this.removePositionById(placeId);
      }
    }

    return Promise.resolve(true);
  }

  private setupMarkerOptions(marker: PositionedDeviceData | Position | LatLon, title: string, color: string, markerType: string): Marker {
    const coordinate: LatLng = { lat: marker.lat || 0, lng: marker.lon || 0 };

    const markerOptions: Marker = {
      coordinate,
      iconUrl: `${environment.webUrl}/assets/custom-icons/pin-${color}.png`,
      title,
      snippet: markerType,
      iconAnchor: { x: 16, y: 32 },
      iconSize: { width: 32, height: 32 },
    };

    return markerOptions;
  }

  private getMarkerColor(device: PositionedDeviceDataWithSilents) {
    if (this.localStorage.markersType === MarkersType.Mount) {
      return 'green';
    }

    // 10505f
    switch (true) {
      case !device.silent && device.undetermined:
        return 'yellow';
      case device.damaged_status:
        return 'red';
      case !device.silent && !device.undetermined && !device.damaged_status:
        return 'green';
      case device.silent && !device.undetermined && !device.damaged_status:
        return 'yellow';
      default:
        return 'white';
    }
  }

  public async switchDevicesView(devices: PositionedDeviceData[]) {
    for (const device of devices) {
      const mapMarker = this.devicesCluster.get(device.device_id);

      if (mapMarker) {
        this.devicesCluster.delete(device.device_id);
        await this.map.removeMarker(mapMarker);

        const markerColor = this.getMarkerColor(device);
        const markerOptions: Marker = this.setupMarkerOptions(device, device.device_id, markerColor, 'Device');
        const mapMarkerId = await this.map.addMarker(markerOptions);

        this.devicesCluster.set(device.device_id, mapMarkerId);
      }
    }

    return Promise.resolve(true);
  }

  public async addDevices(devices: PositionedDeviceData[]) {
    for (const device of devices) {
      // skip existing devices
      if (this.devicesCluster.has(device.device_id)) {
        continue;
      }

      const markerColor = this.getMarkerColor(device);
      const markerOptions: Marker = this.setupMarkerOptions(device, device.device_id, markerColor, 'Device');
      const mapMarkerId = await this.map.addMarker(markerOptions);

      this.devicesCluster.set(device.device_id, mapMarkerId);
    }

    return Promise.resolve(true);
  }

  public async addPositions(positions: Position[]): Promise<boolean> {
    for (const position of positions) {
      // skip existing positions
      if (this.positionsCluster.has(`${position.id}`)) {
        continue;
      }

      const markerOptions: Marker = this.setupMarkerOptions(position, EMPTY_POSITION, 'white', 'Position');
      const mapMarkerId = await this.map.addMarker(markerOptions);

      this.positionsCluster.set(`${position.id}`, mapMarkerId);
    }

    return Promise.resolve(true);
  }

  public async drawCustomMarkers(markers: CustomMarkers) {
    for (const point of markers) {
      const marker = this.customCluster.get(point.key);
      if (marker) {
        this.customCluster.delete(point.key);
        await this.map.removeMarker(marker);
      }

      const markerOptions = this.setupMarkerOptions(
        point.latLon,
        point.key,
        point.options?.color || 'white',
        'Position',
      );
      const addedMarker = await this.map.addMarker(markerOptions);

      this.customCluster.set(point.key, addedMarker);
    }
  }

  public clearCustomMarkers() {
    this.clearCluster(this.customCluster);
  }

  public setCenter(lat: number, lng: number, zoom?: number) {
    const coordinate: LatLng = { lat, lng };

    this.config.center = coordinate;

    if (zoom) {
      this.map.setCamera({ coordinate, zoom });
      this.config.zoom = zoom;
    } else {
      this.map.setCamera({ coordinate });
    }
  }

  public getCenter() {
    return this.config.center;
  }

  public async drawDotInMapCenter() {
    if (this.dotId) {
      await this.map.removePolygons([this.dotId]);
      this.dotId = undefined;
    }

    const options: Polygon = {
      paths: [{ lat: 0, lng: 0 }, { lat: 0, lng: 0 }],
      strokeColor: '#ffc409',
      fillColor: '#ffc409',
      strokeWeight: 5,
      geodesic: true,
      clickable: false,
    };

    const ids = await this.map.addPolygons([options]);
    this.dotId = ids[0];
  }

  public async deletePolyline() {
    if (this.polylineId) {
      this.map.removePolylines([this.polylineId]);
      this.polylineId = undefined;
    }
  }

  public async drawPolyline(path?: LatLng[]) {
    if (path && Array.isArray(path)) {
      const options: Polyline = {
        path,
        strokeColor: '#AA00FF',
        strokeWeight: 5,
        geodesic: true,
        clickable: false,
      };

      const id = await this.map.addPolylines([options]);
      this.polylineId = id[0];
    }
  }

  public moveMarkers(markers: CustomMarkers) {
    this.drawCustomMarkers(markers);
  }

  public async clearMap() {
    this.clearDevicesMarkers();
    this.clearPositionsMarkers();
    await this.map.destroy();
  }
}
