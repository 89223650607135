/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DataAvailableForIncidents,
    DataErrorTypes,
} from './';

/**
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * @type {Array<DataErrorTypes>}
     * @memberof Data
     */
    error_types?: Array<DataErrorTypes>;
    /**
     * @type {Array<DataErrorTypes>}
     * @memberof Data
     */
    reasons?: Array<DataErrorTypes>;
    /**
     * @type {DataAvailableForIncidents}
     * @memberof Data
     */
    available_for_incidents?: DataAvailableForIncidents;
    /**
     * @type {DataAvailableForIncidents}
     * @memberof Data
     */
    available_for_device_states?: DataAvailableForIncidents;
}
