import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { ApiResponse, Project, ProjectCreate, ProjectsFilters, ResponseReason } from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { UserGroup } from 'src/app/model/UserGroup';
import { formatQuery } from './api.utils';
import { HelperProvider } from '../helper/helper';

const DEFAULT_LIMIT = 1000;
const DEFAULT_LIMIT_GROUPS = 100;

interface ResponseUser {
  data: UserGroup[];
}
interface ResponseProjects {
  data: Project[];
}

interface ResponseProject {
  data: Project;
}

@Injectable({
  providedIn: 'root',
})
export class ApiServiceUsers {
  private readonly api = endpointNames.userManagement;

  private urls = {
    groups: '/groups',
    projects: '/projects',
    users: '/users',
    userSub: (sub: string) => `/users/${encodeURIComponent(String(sub))}`,
    projectId: (id: number) => `/projects/${encodeURIComponent(String(id))}`,
  };

  constructor() { }

  public async getUsersGroups(
    helper: HelperProvider,
  ): Promise<{ groups: UserGroup[] }> {
    // helper.showLoading();
    let offset = 0;

    let list: UserGroup[] = [];

    while (list.length === (offset * DEFAULT_LIMIT_GROUPS)) {
      const body = {
        queryStringParameters: formatQuery({
          offset: offset * DEFAULT_LIMIT_GROUPS,
          limit: DEFAULT_LIMIT_GROUPS,
        }),
      };

      const response: ApiResponse & ResponseUser = await API.get(
        this.api,
        this.urls.groups,
        body,
      ).catch(errorHandler);

      if (response.reason === ResponseReason.Ok) {
        list = list.concat(response.data);
        offset += 1;
        // helper.hideLoading();
      } else {
        helper.alert(response.message, 'Getting user groups error');
        // helper.hideLoading();
        break;
      }
    }

    return { groups: list };
  }

  public async projects(filters?: ProjectsFilters): Promise<{ projects: Project[], message?: string }> {
    let offset = 0;

    let list: Project[] = [];

    while (list.length === (offset * DEFAULT_LIMIT)) {
      const body = {
        queryStringParameters: formatQuery({
          ...(filters?.owner_id && { owner_id: filters?.owner_id }),
          offset: offset * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      };

      const response: ApiResponse & ResponseProjects = await API.get(this.api, this.urls.projects, body).catch(errorHandler);

      if (response.reason === ResponseReason.Ok) {
        list = list.concat(response.data);
        offset += 1;
      } else {
        return { projects: [], message: response.message || 'Unable to get projects' };
      }
    }

    return { projects: list };
  }

  public async createProject(project: ProjectCreate, helper: HelperProvider): Promise<number | void> {
    const body = {
      body: project,
    };

    const response: ApiResponse & {data: Project} = await API.post(this.api, this.urls.projects, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data.id;
    } else {
      helper.alert(response.message || 'Error while creating project');
    }
  }

  public async deleteUser(sub: string, helper: HelperProvider): Promise<boolean | void> {
    const response: ApiResponse = await API.del(this.api, this.urls.userSub(sub), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    } else {
      helper.alert(response.message || 'Error while deleting user data');
    }
  }

  public async getProjectById(projectId: number, helper?: HelperProvider): Promise<Project | undefined> {
    const response: ResponseProject = await API.get(this.api, this.urls.projectId(projectId), {}).catch(errorHandler);

    if (response.data) {
      return response.data;
    } else {
      if (helper) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        helper.alert(response.data as any as string || 'Try again later');
      }
      return;
    }
  }

}
