/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DataAvailableForIncidents
 */
export interface DataAvailableForIncidents {
    /**
     * @type {Array<string>}
     * @memberof DataAvailableForIncidents
     */
    reasons?: Array<string>;
    /**
     * @type {Array<string>}
     * @memberof DataAvailableForIncidents
     */
    error_types?: Array<string>;
}
