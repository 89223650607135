export enum LocalSettings {
  MapType = 'mapType',
  MarkersType = 'MarkersType',
  AutoCalibration = 'autoCalibration',
  SnapToRoad = 'snapToRoad',
  IntervalPlacing = 'intervalPlacing',
  IntervalMeters = 'intervalMeters',
  CustomIdGeneration = 'customIdGeneration',
  LightUpQR = 'lightUpQR',
  DarkMode = 'darkMode',
  PreEnabledOptions = 'preenabledOptions',

  SelectedOwner = 'selectedOwner',
  SelectedProject = 'selectedProject',
}
