/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Incidents history model for statistic
 * @export
 * @interface IncidentHistory
 */
export interface IncidentHistory {
    /**
     * @type {string}
     * @memberof IncidentHistory
     */
    date: string;
    /**
     * @type {number}
     * @memberof IncidentHistory
     */
    total: number;
}
