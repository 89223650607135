/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DeviceStateSeverityEnumModel,
    ExtendedIncidentsTypesDeviceStateEnumModel,
    IncidentReasonEnum,
    IncidentsTypesDeviceStateEnumModel
} from './';

/**
 * Datatype for device states v2
 * @export
 * @interface DeviceStatesV2
 */
export interface DeviceStatesV2 {
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    device_id: string;
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    zone_name?: string;
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    group_name?: string;
    /**
     * @type {number}
     * @memberof DeviceStatesV2
     */
    zone_id?: number;
    /**
     * @type {number}
     * @memberof DeviceStatesV2
     */
    group_id?: number;
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    project_name?: string;
    /**
     * @type {IncidentsTypesDeviceStateEnumModel | ExtendedIncidentsTypesDeviceStateEnumModel}
     * @memberof DeviceStatesV2
     */
    incident_type?: IncidentsTypesDeviceStateEnumModel | ExtendedIncidentsTypesDeviceStateEnumModel;
    /**
     * @type {IncidentReasonEnum}
     * @memberof DeviceStatesV2
     */
    reason?: IncidentReasonEnum;
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    incident_date?: string;
    /**
     * @type {number}
     * @memberof DeviceStatesV2
     */
    days_broken?: number;
    /**
     * @type {number}
     * @memberof DeviceStatesV2
     */
    latitude?: number;
    /**
     * @type {number}
     * @memberof DeviceStatesV2
     */
    longitude?: number;
    /**
     * @type {DeviceStateSeverityEnumModel}
     * @memberof DeviceStatesV2
     */
    severity?: DeviceStateSeverityEnumModel;
    /**
     * @type {string}
     * @memberof DeviceStatesV2
     */
    network_id?: string;
    incident_id?: string;
}
