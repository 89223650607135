import { SoftType, PositionedDeviceData, Position, Zone, Group, Level } from './generated';

/**
 * Generated types
 */
export * from './generated';
export * from './generated-um';

/**
 * Manual types
 */
export const SoftTypes = {
  [SoftType.General]: 'General',
  [SoftType.ParkingSensor]: 'Parking sensor',
  [SoftType.StandaloneCarCounter]: 'Standalone car counter',
};

export interface DeviceCardModalResult {
  unbind?: boolean;
  edit?: {
    [EditAdditionalFields.Zone]: Zone,
    [EditAdditionalFields.Level]?: Level,
    [EditAdditionalFields.Group]: Group,
  };
}

export enum EditAdditionalFields {
  Zone = 'zone',
  Group = 'group',
  Level = 'level',
  Position = 'position',
  Device = 'device',
}

export interface PositionCardModalResult {
  bind?: boolean;
  deleted?: boolean;
  edit?: boolean;
  binded?: string;
  additionalData?: {
    [EditAdditionalFields.Zone]?: Zone,
    [EditAdditionalFields.Group]?: Group,
    [EditAdditionalFields.Level]?: Level,
  };
}

export interface BindModalResult {
  accurate: boolean;
}

export interface CreateZoneModalResult {
  id: number;
}

export interface BindDeviceResult {
  binded?: boolean;
}

export interface AccuratePositioningModalResult {
  ok: boolean;
}

export interface BindDevicesListModalResult {
  done?: {
    positions: number[];
    devices: string[];
  };
  cancel?: boolean;
}

export interface DeviceFindModalResult {
  device: PositionedDeviceData;
}

export interface DecodedJwt {
  role: 'admin' | 'user';
  'dm.zone': string;
  'dm.device': string;
  user_group: string;
  [key: string]: string;
}

export * from './store-keys';
export * from './filters';

export type MarkersData = [PositionedDeviceData[], Position[]];

export interface LocationFindModalResult {
  position: { lat: number, lon: number };
}
