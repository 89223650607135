/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ExtendedIncidentsTypesEnumModel,
    IncidentReasonEnum,
    IncidentsTypesEnumModel,
} from './';

/**
 * Create incident schema
 * @export
 * @interface CreateIncidentBody
 */
export interface CreateIncidentBody {
    /**
     * @type {string}
     * @memberof CreateIncidentBody
     */
    device_id: string;
    /**
     * @type {string}
     * @memberof CreateIncidentBody
     */
    trace_id?: string;
    /**
     * @type {Date}
     * @memberof CreateIncidentBody
     */
    error_datetime: Date;
    /**
     * @type {string}
     * @memberof CreateIncidentBody
     */
    comment?: string;
    /**
     * @type {boolean}
     * @memberof CreateIncidentBody
     */
    is_resolved?: boolean;
    /**
     * @type {boolean}
     * @memberof CreateIncidentBody
     */
    damaging?: boolean;
    /**
     * @type {IncidentReasonEnum}
     * @memberof CreateIncidentBody
     */
    reason?: IncidentReasonEnum;
    /**
     * @type {IncidentsTypesEnumModel | ExtendedIncidentsTypesEnumModel}
     * @memberof CreateIncidentBody
     */
    error_type: IncidentsTypesEnumModel | ExtendedIncidentsTypesEnumModel;
}
