/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DeviceStatesV2,
    ResponseReason,
} from './';

/**
 * Response with list of devices
 * @export
 * @interface ResponseDeviceStatesV2ListBody
 */
export interface ResponseDeviceStatesV2ListBody {
    /**
     * @type {Array<DeviceStatesV2>}
     * @memberof ResponseDeviceStatesV2ListBody
     */
    data?: Array<DeviceStatesV2>;
    /**
     * @type {string}
     * @memberof ResponseDeviceStatesV2ListBody
     */
    message?: string;
    /**
     * @type {number}
     * @memberof ResponseDeviceStatesV2ListBody
     */
    total?: number;
    /**
     * @type {ResponseReason}
     * @memberof ResponseDeviceStatesV2ListBody
     */
    reason?: ResponseReason;
}
