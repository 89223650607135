/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    HardwareErrorIncident,
    Incident,
    ResponseReason,
    UnexpectedResetErrorIncident,
} from './';

/**
 * Response with list of incidents
 * @export
 * @interface ResponseIncidentBody
 */
export interface ResponseIncidentBody {
    /**
     * @type {Incident & UnexpectedResetErrorIncident & HardwareErrorIncident}
     * @memberof ResponseIncidentBody
     */
    data?: Incident & UnexpectedResetErrorIncident & HardwareErrorIncident;
    /**
     * @type {string}
     * @memberof ResponseIncidentBody
     */
    message?: string;
    /**
     * @type {ResponseReason}
     * @memberof ResponseIncidentBody
     */
    reason?: ResponseReason;
}
