/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Object with not resolved incidents
 * @export
 * @interface DevicePivotIncidents
 */
export interface DevicePivotIncidents {
    /**
     * @type {boolean}
     * @memberof DevicePivotIncidents
     */
    unexpected_reset_error?: boolean;
    /**
     * @type {boolean}
     * @memberof DevicePivotIncidents
     */
    last_message_time_error?: boolean;
    /**
     * @type {boolean}
     * @memberof DevicePivotIncidents
     */
    hardware_error?: boolean;
}
