/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    IncidentReasonEnum,
} from './';

/**
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * @type {string}
     * @memberof InlineObject
     */
    comment?: string;
    /**
     * @type {boolean}
     * @memberof InlineObject
     */
    is_resolved?: boolean;
    /**
     * @type {boolean}
     * @memberof InlineObject
     */
    damaging?: boolean;
    /**
     * @type {IncidentReasonEnum}
     * @memberof InlineObject
     */
    reason?: IncidentReasonEnum;
}
