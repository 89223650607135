import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { adminErrorTypes, ApiResponse, ApiResponseData, ResponseReason, userErrorTypes, WizardResult } from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { CreateIncidentBody, DeviceStatesV2, Incident, IncidentReasonEnum } from 'src/app/model/monitoring';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceMonitoring {
  private readonly monitApi = endpointNames.monitoring;
  private readonly LIMIT = 3000;

  private readonly urls = {
    incidents: '/incidents',
    states: '/devices/states',
    incidentById: (id: number) => `/incidents/${encodeURIComponent(String(id))}`,
  };

  constructor() { }

  public async getDevicesIncidents(admin: boolean, filters: { project_id: number }): Promise<{ data?: DeviceStatesV2[]; error?: string | null }> {
    let offset = 0;

    let data: Incident[] = [];

    while (data.length === (offset * this.LIMIT)) {
      const body = {
        queryStringParameters: {
          offset: offset * this.LIMIT,
          limit: this.LIMIT,
          incident_type: (admin ? adminErrorTypes : userErrorTypes).join(','),
          project_id: filters.project_id,
        },
      };

      const response: ApiResponseData = await API.get(this.monitApi, this.urls.states, body).catch(error => error.response);

      if (response.reason === ResponseReason.Ok) {
        data = data.concat(response.data);
        offset += 1;
      } else {
        return { error: response.message || 'Server Error' };
      }
    }

    return { data };
  }

  public async resolveIncident(id: number): Promise<ApiResponse> {
    const body: Partial<CreateIncidentBody> = {
      comment: 'Resolve device as OK by Replacing App',
      is_resolved: true,
      reason: IncidentReasonEnum.NotConfirmed,
    };

    return await API.patch(
      this.monitApi,
      this.urls.incidentById(id),
      { body },
    ).catch(this.error);
  }

  private setupReason(status: WizardResult): IncidentReasonEnum {
    switch (true) {
      case status.hidden:
        return IncidentReasonEnum.HiddenProblem;
      case status.missed:
        return IncidentReasonEnum.Missing;
      case status.caseDamage:
        return IncidentReasonEnum.BrokenCasing;
      default:
        return IncidentReasonEnum.NotSpecified;
    }
  }

  public async updateIncident(id: number, data: WizardResult, setAsDamage?: boolean): Promise<ApiResponse> {
    const reason = this.setupReason(data);
    const body: Partial<CreateIncidentBody> = {
      comment: setAsDamage ? 'Resolved as damage by Replacing App' : `Update incident status as ${reason} by Replacing App`,
      is_resolved: setAsDamage,
      reason,
      ...(setAsDamage && { damaging: true }),
    };

    return await API.patch(
      this.monitApi,
      this.urls.incidentById(id),
      { body },
    ).catch(this.error);
  }

  private error(data: any): ApiResponse {
    if (!data) {
      return { message: 'Server Error', reason: ResponseReason.ServerError };
    }

    if (data.response) {
      return data.response.data;
    }

    return { message: 'Server Error', reason: ResponseReason.ServerError };
  }

}
