/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatisticMetricEnum {
    SilentOk = 'silent_ok',
    IncidentsNumber = 'incidents_number',
    NotResolvedNumber = 'not_resolved_number',
    FailuresHistory = 'failures_history',
    SilentHistory = 'silent_history',
    TotalFailures = 'total_failures'
}

