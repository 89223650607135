import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  ApiResponse,
  Device,
  ResponseReason,
  PositionedDeviceData,
  DevicesFilters,
  DeviceUpdateFields,
  PositionForBind,
  ResponsePositionedDevicesWithTotal,
  ResponseDevices,
  ResponseDevice
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { BehaviorSubject } from 'rxjs';
import { HelperProvider } from '../helper/helper';
import { errorHandler, DEFAULT_LIMIT } from 'src/app/app.utils';
import { formatQuery } from './api.utils';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceDevices {
  private readonly dmApi = endpointNames.deviceManagement;

  private devicesUrls = {
    findPositionedDevices: '/devices/positioned/find',
    setBrokenDevices: '/devices/setBrokenDevices',
    replaceDevices: '/devices/replaceDevices',
    bindToPosition: (deviceId: string) => `/device/${encodeURIComponent(String(deviceId))}/bindToPosition`,
    unbindFromPosition: (deviceId: string) => `/device/${encodeURIComponent(String(deviceId))}/unbindFromPosition`,
    findDevices: '/devices/find',
    coldData: (id: number) => `/device/${encodeURIComponent(String(id))}/coldData`,
    devices: '/devices',
    deviceId: (id: string) => `/device/${encodeURIComponent(String(id))}`,
  };

  constructor() { }

  public async getPositionedDevices(
    filters: DevicesFilters,
    helper: HelperProvider,
    loader?: BehaviorSubject<number>,
    stopLoadingDevices?: BehaviorSubject<boolean>,
  ): Promise<{ devices: PositionedDeviceData[]; }> {
    let offset = 0;

    let list: PositionedDeviceData[] = [];

    while (list.length === (offset * DEFAULT_LIMIT)) {
      if (stopLoadingDevices?.getValue()) {
        break;
      }

      const body = {
        queryStringParameters: formatQuery({
          device_id: filters.device_id,
          project_id: filters.project_id,
          force: filters.force,
          active: filters.active,
          offset: offset * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      };

      const response: ResponsePositionedDevicesWithTotal = await API.get(
        this.dmApi,
        this.devicesUrls.findPositionedDevices,
        body,
      ).catch(errorHandler);

      if (response.reason === ResponseReason.Ok) {
        list = list.concat(response.data);
        offset += 1;

        if (loader) {
          loader.next(loader.getValue() + offset);
        }
      } else {
        helper.alert(response.message, 'Getting devices error');
        break;
      }
    }

    return { devices: list };
  }

  public async getDevices(
    helper: HelperProvider,
    filters: DevicesFilters,
  ): Promise<Device[] | void> {
    const body = {
      queryStringParameters: formatQuery({
        limit: filters.limit,
        offset: filters.offset,
        device_id: filters.device_id,
      }),
    };

    const response: ResponseDevices = await API.get(this.dmApi, this.devicesUrls.findDevices, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      helper.alert(response.message, 'Getting devices error');
    }
  }

  public async bindDeviceToPosition(deviceId: string, position: PositionForBind): Promise<{ error?: string; data: Device }> {
    const queries = {
      body: {
        ...(position.position_id && { position_id: position.position_id }),
        ...(position.position_data && { position_data: position.position_data }),
      },
    };

    const response: ApiResponse & {data: Device} =
      await API.post(this.dmApi, this.devicesUrls.bindToPosition(deviceId), queries).catch(errorHandler);

    return {
      ...(response.reason !== ResponseReason.Ok && { error: response.message }),
      data: response.data,
    };
  }

  public async getDeviceById(deviceId: string): Promise<{ error?: string; device?: Device }> {
    const response: ResponseDevice = await API.get(this.dmApi, this.devicesUrls.deviceId(deviceId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { device: response.data };
    } else {
      return { error: response.message || 'Server Error' };
    }
  }

  public async getPositionedDevicesById(deviceId: string[], helper?: HelperProvider): Promise<{ devices?: PositionedDeviceData[] }> {
    const body = {
      queryStringParameters: formatQuery({
        device_id: deviceId,
      }),
    };

    const response: ResponsePositionedDevicesWithTotal = await API.get(
      this.dmApi,
      this.devicesUrls.findPositionedDevices,
      body,
    ).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { devices: response.data || [] };
    }

    if (helper) {
      helper.alert(response.message || 'Error while getting device');
    }

    return { devices: [] };
  }

  public async replaceDevices(oldDevice: string, newDevice: string): Promise<ApiResponse> {
    const body = {
      body: { oldDevice, newDevice },
    };

    const response: ApiResponse = await API.put(this.dmApi, this.devicesUrls.replaceDevices, body).catch(errorHandler);

    return response;
  }

  public async undind(id: string, positionId: number): Promise<{ error?: string; }> {
    const body = {
      body: {
        position_id: positionId,
      },
    };

    const response: ApiResponse = await API.post(this.dmApi, this.devicesUrls.unbindFromPosition(id), body).catch(errorHandler);

    return {
      ...(response.reason !== ResponseReason.Ok && { error: response.message }),
    };
  }

  public async update(device: DeviceUpdateFields, id: string, helper?: HelperProvider): Promise<boolean | void> {
    const body = {
      body: device,
    };

    const response: ApiResponse = await API.put(this.dmApi, this.devicesUrls.deviceId(id), body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    }

    if (helper) {
      helper.alert(response.message || 'Error while updating device');
    }
  }
}
