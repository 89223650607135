import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { LocalSettings } from 'src/app/model';
import { AlertController } from '@ionic/angular';
import { MapType } from '@capacitor/google-maps';
import { isInitThemeDark, setAppTheme } from '../app.utils';

@Injectable({
  providedIn: 'root',
})
export class LocaLstorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private alertController: AlertController,
  ) { }

  private readonly MAPTYPE = new BehaviorSubject<MapType>(MapType.Normal);
  readonly mapType$ = this.MAPTYPE.asObservable();

  private readonly AUTOCALIBRATION = new BehaviorSubject<boolean>(true);
  readonly autoCalibration$ = this.AUTOCALIBRATION.asObservable();

  private readonly CUSTOMIDGENERATION = new BehaviorSubject<boolean>(false);
  readonly customIdGeneration$ = this.CUSTOMIDGENERATION.asObservable();

  private readonly DARKMODE = new BehaviorSubject<boolean>(false);
  readonly darkMode$ = this.DARKMODE.asObservable();

  private readonly INTERVALPLACING = new BehaviorSubject<boolean>(false);
  readonly intervalPlacing$ = this.INTERVALPLACING.asObservable();

  private readonly INTERVALMETERS = new BehaviorSubject<number>(3);
  readonly intervalMeters$ = this.INTERVALMETERS.asObservable();

  private readonly LIGHTUPQR = new BehaviorSubject<boolean>(false);
  readonly lightUpQR$ = this.LIGHTUPQR.asObservable();

  private readonly SNAPTOROAD = new BehaviorSubject<boolean>(false);
  readonly snapToRoad$ = this.SNAPTOROAD.asObservable();

  private readonly PREENABLEDOPTIONS = new BehaviorSubject<boolean>(false);


  private readonly SELECTEDOWNER = new BehaviorSubject<number | undefined>(undefined);
  readonly selectedOwner$ = this.SELECTEDOWNER.asObservable();

  private readonly SELECTEDPROJECT = new BehaviorSubject<number | undefined>(undefined);
  readonly selectedProject$ = this.SELECTEDPROJECT.asObservable();

  /* INIT START */
  public async init() {
    this._storage = await this.storage.create();
    const autoCalibration = await this._storage?.get(LocalSettings.AutoCalibration);
    const lightUpQR = await this._storage?.get(LocalSettings.LightUpQR);

    this.autoCalibration = typeof autoCalibration === 'boolean' ? autoCalibration : true;
    this.lightUpQR = typeof lightUpQR === 'boolean' ? lightUpQR : false;
    const mapType = await this._storage?.get(LocalSettings.MapType);
    this.mapType = mapType === MapType.Normal || mapType === MapType.Hybrid ? mapType : MapType.Normal;
    this.customIdGeneration = false;
    this.darkMode = await this.initThemeValue();
    this.intervalMeters = (await this._storage?.get(LocalSettings.IntervalMeters)) || 3;
    this.intervalPlacing = !!(await this._storage?.get(LocalSettings.IntervalPlacing));
    this.snapToRoad = !!(await this._storage?.get(LocalSettings.SnapToRoad));
  }

  public async initProject() {
    this.selectedOwner = (await this._storage?.get(LocalSettings.SelectedOwner)) || undefined;
    this.selectedProject = (await this._storage?.get(LocalSettings.SelectedProject)) || undefined;
  }

  public clear = () => {
    this._storage?.clear();

    this.autoCalibration = false;
    this.lightUpQR = false;
    this.mapType = MapType.Normal;
    this.customIdGeneration = false;
    this.darkMode = false;
    this.intervalMeters = 3;
    this.intervalPlacing = false;
    this.snapToRoad = false;
  }

  public async checkPreenabledOptions() {
    /**
      * https://nwaveio.atlassian.net/browse/MAIN-2116
      */
    const preEnabledOptions = await this._storage?.get(LocalSettings.PreEnabledOptions);

    if (!preEnabledOptions && !this.autoCalibration) {
      this.autoCalibration = true;
      this.lightUpQR = false;
      this.preEnabledOptions = true;

      const alert = await this.alertController.create({
        message: 'Automatic devices calibration after QR code scanning is enabled by default. You can disable it using Settings menu',
        buttons: ['OK'],
      });

      await alert.present();
    }
  }

  /* PREENABLEDOPTIONS */
  get preEnabledOptions(): boolean {
    return this.PREENABLEDOPTIONS.getValue();
  }
  set preEnabledOptions(val: boolean) {
    this._storage?.set(LocalSettings.PreEnabledOptions, val);
    this.PREENABLEDOPTIONS.next(val);
  }

  /* AUTOCALIBRATION */
  get autoCalibration(): boolean {
    return this.AUTOCALIBRATION.getValue();
  }
  set autoCalibration(val: boolean) {
    this._storage?.set(LocalSettings.AutoCalibration, val);
    this.AUTOCALIBRATION.next(val);
  }

  /* MAPTYPE */
  get mapType(): MapType {
    return this.MAPTYPE.getValue();
  }
  set mapType(val: MapType) {
    this._storage?.set(LocalSettings.MapType, val);
    this.MAPTYPE.next(val);
  }

  /* CUSTOMIDGENERATION */
  get customIdGeneration(): boolean {
    return this.CUSTOMIDGENERATION.getValue();
  }
  set customIdGeneration(val: boolean) {
    this._storage?.set(LocalSettings.CustomIdGeneration, val);
    this.CUSTOMIDGENERATION.next(val);
  }

  /* DARKMODE */
  get darkMode(): boolean {
    return this.DARKMODE.getValue();
  }
  set darkMode(val: boolean) {
    this._storage?.set(LocalSettings.DarkMode, val);
    this.DARKMODE.next(val);

    setAppTheme(val ? 'dark' : 'light');
  }

  private async initThemeValue(): Promise<boolean> {
    const localValue = await this._storage?.get(LocalSettings.DarkMode);

    if (typeof localValue === 'boolean') {
      return localValue;
    }

    return isInitThemeDark();
  }

  /* INTERVALPLACING */
  get intervalPlacing(): boolean {
    return this.INTERVALPLACING.getValue();
  }
  set intervalPlacing(val: boolean) {
    this.INTERVALPLACING.next(val);
    this._storage?.set(LocalSettings.IntervalPlacing, val);
  }

  /* INTERVALMETERS */
  get intervalMeters(): number {
    return this.INTERVALMETERS.getValue();
  }
  set intervalMeters(val: number) {
    const valNum = Math.abs(Number(val)) || 1;
    this._storage?.set(LocalSettings.IntervalMeters, valNum);
    this.INTERVALMETERS.next(valNum);
  }

  /* LIGHTUPQR */
  get lightUpQR(): boolean {
    return this.LIGHTUPQR.getValue();
  }
  set lightUpQR(val: boolean) {
    this._storage?.set(LocalSettings.LightUpQR, val);
    this.LIGHTUPQR.next(val);
  }

  /* SNAPTOROAD */
  get snapToRoad(): boolean {
    return this.SNAPTOROAD.getValue();
  }
  set snapToRoad(val: boolean) {
    this._storage?.set(LocalSettings.SnapToRoad, val);
    this.SNAPTOROAD.next(val);
  }

  /* SELECTEDOWNER */
  get selectedOwner(): number | undefined {
    return this.SELECTEDOWNER.getValue();
  }
  set selectedOwner(id: number | undefined) {
    this._storage?.set(LocalSettings.SelectedOwner, id);
    this.SELECTEDOWNER.next(id);
  }

  /* SELECTEDPROJECT */
  get selectedProject(): number | undefined {
    return this.SELECTEDPROJECT.getValue();
  }
  set selectedProject(id: number | undefined) {
    this._storage?.set(LocalSettings.SelectedProject, id);
    this.SELECTEDPROJECT.next(id);
  }
}
