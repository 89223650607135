/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    IncidentHistory,
} from './';

/**
 * Incidents statistic cumulative history model
 * @export
 * @interface IncidentStatisticHistory
 */
export interface IncidentStatisticHistory {
    /**
     * @type {Array<IncidentHistory>}
     * @memberof IncidentStatisticHistory
     */
    failures_history?: Array<IncidentHistory>;
    /**
     * @type {Array<IncidentHistory>}
     * @memberof IncidentStatisticHistory
     */
    silent_history?: Array<IncidentHistory>;
}
