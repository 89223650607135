/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Types of Failures only for admins or extended user roles
 * @export
 * @enum {string}
 */
export enum ExtendedIncidentsTypesDeviceStateEnumModel {
    Manual = 'manual',
    HardwareError = 'hardware_error',
    LastMessageTimeError = 'last_message_time_error',
    AnomalousStatusDuration = 'anomalous_status_duration',
    UnexpectedResetError = 'unexpected_reset_error',
    NightLightSensorError = 'night_light_sensor_error',
    DaylightSensorError = 'daylight_sensor_error',
    MagnetometerError = 'magnetometer_error',
    PositionedButInactive = 'positioned_but_inactive'
}

