import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Zone, Group, PositionedDeviceData, Position, MarkersData, Level, GroupsFilters, LevelsFilters, Label, ZonesFilters } from 'src/app/model';
import { ApiService } from 'src/app/providers/api/api.service';
import { HelperProvider } from 'src/app/providers';
import { sortByName, sortByLabelName } from 'src/app/app.utils';
import { IncidentsTypesEnumModel } from '../model/monitoring';
import { PositionedDeviceDataWithSilents } from '../model/placedDeviceDataWithSilents';

interface PreselectedValues {
  zoneId?: number;
  levelId?: number;
  groupId?: number;
  positionId?: number;
  deviceId?: string;
}

interface ZonesState { loading: boolean; data: Zone[]; }
interface LevelsState { loading: boolean; data: Level[]; }
interface GroupsState { loading: boolean; data: Group[]; }
interface LabelsState { loading: boolean; data: Label[]; }

@Injectable({ providedIn: 'root' })
export class DmStoreService {
  constructor(
    private api: ApiService,
    private helper: HelperProvider,
  ) { }

  public device: PositionedDeviceData | null = null;
  public position: Position | null = null;
  public location: { lat: number, lon: number } = {
    lat: 0,
    lon: 0,
  };


  /**
   * ZONES
   */
  private readonly ZONES = new BehaviorSubject<ZonesState>({ loading: false, data: [] });
  readonly zones$ = this.ZONES.asObservable();

  public readonly selectedZone = new BehaviorSubject<Zone | undefined>(undefined);
  readonly selectedZone$: Observable<Zone | undefined> = this.selectedZone.asObservable();

  get zones(): Zone[] {
    return this.ZONES.getValue().data;
  }
  set zones(data: Zone[]) {
    this.ZONES.next({ data, loading: this.zonesLoading });
  }
  get zonesLoading(): boolean {
    return this.ZONES.getValue().loading;
  }
  set zonesLoading(loading: boolean) {
    this.ZONES.next({ data: this.zones, loading });
  }


  /**
   * LEVELS
   */
  private readonly LEVELS = new BehaviorSubject<LevelsState>({ loading: false, data: [] });
  readonly levels$ = this.LEVELS.asObservable();

  public readonly selectedLevel = new BehaviorSubject<Level | undefined>(undefined);
  readonly selectedLevel$: Observable<Level | undefined> = this.selectedLevel.asObservable();

  get levels(): Level[] {
    return this.LEVELS.getValue().data;
  }
  set levels(data: Level[]) {
    this.LEVELS.next({ data, loading: false });
  }
  get levelsLoading(): boolean {
    return this.LEVELS.getValue().loading;
  }
  set levelsLoading(loading: boolean) {
    this.LEVELS.next({ data: this.levels, loading });
  }


  /**
   * GROUPS
   */
  private readonly GROUPS = new BehaviorSubject<GroupsState>({ loading: false, data: [] });
  readonly groups$ = this.GROUPS.asObservable();

  public readonly selectedGroup = new BehaviorSubject<Group | undefined>(undefined);
  readonly selectedGroup$: Observable<Group | undefined> = this.selectedGroup.asObservable();

  get groups(): Group[] {
    return this.GROUPS.getValue().data;
  }
  set groups(data: Group[]) {
    this.GROUPS.next({ data, loading: false });
  }
  get groupsLoading(): boolean {
    return this.GROUPS.getValue().loading;
  }
  set groupsLoading(loading: boolean) {
    this.GROUPS.next({ data: this.groups, loading });
  }


  /**
   * POSITIONS
   */
  private readonly POSITIONS = new BehaviorSubject<Position[]>([]);
  readonly positions$ = this.POSITIONS.asObservable();

  private selectedPositionId: number;
  readonly selectedPosition$ = this.positions$.pipe(
    map(() => this.positions.find(position => position.id === this.selectedPositionId)),
  );
  get positions(): Position[] {
    return this.POSITIONS.getValue();
  }
  set positions(val: Position[]) {
    this.POSITIONS.next(val);
  }


  /**
   * DEVICES
   */
  private readonly DEVICES = new BehaviorSubject<PositionedDeviceData[]>([]);
  readonly devices$ = this.DEVICES.asObservable();

  get devices(): PositionedDeviceData[] {
    return this.DEVICES.getValue();
  }
  set devices(val: PositionedDeviceData[]) {
    this.DEVICES.next(val);
  }

  /* DEVICES AND POSITIONS ON MAP */
  private readonly MARKERS = new BehaviorSubject<MarkersData>([[], []]);
  readonly markers$ = this.MARKERS.asObservable();

  get markers(): MarkersData {
    return this.MARKERS.getValue();
  }
  set markers(val: MarkersData) {
    this.MARKERS.next(val);
  }


  /**
   * PRESELECTED_VALUES
   */
  private readonly PRESELECTED_VALUES = new BehaviorSubject<PreselectedValues>({});
  readonly preselectedValues$ = this.PRESELECTED_VALUES.asObservable();

  get preselectedValues(): PreselectedValues {
    return this.PRESELECTED_VALUES.getValue();
  }
  set preselectedValues(val: PreselectedValues) {
    this.PRESELECTED_VALUES.next(val);
  }


  /**
   * LABELS
   */
  private readonly LABELS = new BehaviorSubject<LabelsState>({ loading: false, data: [] });
  readonly labels$ = this.LABELS.asObservable();

  public readonly selectedLabels = new BehaviorSubject<Label[]>([]);
  readonly selectedLabels$: Observable<Label[]> = this.selectedLabels.asObservable();

  get labels(): Label[] {
    if (this.selectedZone.value) {
      const labels = this.LABELS.getValue().data;
      return labels.filter(label => label.owner_id === this.selectedZone.value?.owner_id);
    } else {
      return [];
    }
  }
  set labels(data: Label[]) {
    this.LABELS.next({ data, loading: this.labelsLoading });
  }
  get labelsLoading(): boolean {
    return this.LABELS.getValue().loading;
  }
  set labelsLoading(loading: boolean) {
    this.LABELS.next({ data: this.labels, loading });
  }


  // selected device for edit
  public readonly chosenDevice = new BehaviorSubject<PositionedDeviceData | null>(this.device);
  readonly chosenDevice$: Observable<PositionedDeviceData | null> = this.chosenDevice.asObservable();

  // selected  position for edit
  public readonly chosenPosition = new BehaviorSubject<Position | null>(this.position);
  readonly chosenPosition$: Observable<Position | null> = this.chosenPosition.asObservable();

  public readonly LOADING = new BehaviorSubject<number>(1);
  readonly loading$ = this.LOADING.asObservable();

  public readonly FORCE_STOP_LOADING = new BehaviorSubject<boolean>(false);
  readonly forceStopLoading$ = this.FORCE_STOP_LOADING.asObservable();

  public readonly LOADINGGROUPBIND = new BehaviorSubject<[number, number]>([0, 0]);
  readonly loadingBindGroup$ = this.LOADINGGROUPBIND.asObservable();

  set loadingBindGroup(val: [number, number]) {
    this.LOADINGGROUPBIND.next(val);
  }

  /* INIT DEVICES AND POSITIONS */
  public async initDevicesAndPositions(project: number, isAdmin: boolean) {
    const result: {
      devices: PositionedDeviceDataWithSilents[],
      positions: Position[],
    } = {
      devices: [],
      positions: [],
    };

    this.LOADING.next(10);

    const { devices } = await this.api.devices.getPositionedDevices({ project_id: [project] }, this.helper, this.LOADING, this.FORCE_STOP_LOADING);
    result.devices = devices;

    if (this.FORCE_STOP_LOADING.getValue()) {
      this.LOADING.next(0);
      return;
    }

    this.LOADING.next(50);

    const { positions } = await this.api.positions.getPositions({ bound: 'false', project_id: [project] }, this.helper, this.LOADING, this.FORCE_STOP_LOADING);
    result.positions = positions;

    if (this.FORCE_STOP_LOADING.getValue()) {
      this.LOADING.next(0);
      return;
    }

    // find silents
    const incidents = await this.api.monitoring.getDevicesIncidents(isAdmin, { project_id: project });
    if (!incidents.error && incidents.data) {
      for (const incident of incidents.data) {
        const index = result.devices.findIndex(d => d.device_id.toUpperCase() === incident.device_id.toUpperCase());
        if (result.devices[index]) {
          if (
            incident.incident_type?.toLowerCase() === 'No Messages'.toLowerCase() ||
            incident.incident_type?.toLowerCase() === IncidentsTypesEnumModel.LastMessageTimeError.toLowerCase()
          ) {
            result.devices[index].silent = true;
          } else {
            result.devices[index].undetermined = !incident.reason;
          }

          result.devices[index].incidentId = Number(incident.incident_id) || 0;
        }
      }
    }

    if (this.FORCE_STOP_LOADING.getValue()) {
      this.LOADING.next(0);
      return;
    }

    this.LOADING.next(100);

    this.devices = result.devices;
    this.positions = result.positions;

    this.markers = [devices, positions];

    setTimeout(() => {
      this.LOADING.next(0);

      if (this.FORCE_STOP_LOADING.getValue()) {
        return;
      }

      if (this.preselectedValues.positionId) {
        this.selectPosition(String(this.preselectedValues.positionId));
      }
    }, 500);
  }

  public stopLoadingDevices() {
    this.FORCE_STOP_LOADING.next(true);
  }

  public proceedLoadingDevices() {
    this.FORCE_STOP_LOADING.next(false);
  }

  public selectZone(id?: number) {
    this.selectGroup();
    this.selectLevel();

    if (id) {
      this.fetchLevels({ zone_id: [id] });
      this.fetchGroups({ zone_id: [id] });
    } else {
      this.groups = [];
      this.levels = [];
      // this.selectedGroup.next(undefined);
    }
    this.selectedZone.next(this.zones.find(zone => zone.id === id));
  }

  public selectLevel(id?: number) {
    if (id) {
      this.fetchGroups({ level_id: [id] });
    }

    this.selectedLevel.next(this.levels.find(level => level.id === id));
  }

  public selectGroup(id?: number) {
    this.selectedGroup.next(this.groups.find(group => group.id === id));
  }

  public selectLabels(ids: number[] = []) {
    this.selectedLabels.next(this.labels.filter(label => ids.includes(label.id)));
  }

  public selectLabelsForEdit(labels: Label[] = []) {
    const ids = labels?.map(l => l.id) || [];
    this.selectLabels(ids);
  }

  public selectDevice(id: string): void {
    const select = this.devices.find(device => device.device_id === id) || null;

    this.chosenDevice.next(select);
  }

  public selectFoundDevice(foundDevice: PositionedDeviceData): void {
    const select = this.devices.find(device => device.device_id === foundDevice.device_id);

    if (select) {
      this.chosenDevice.next(select);
      return;
    }

    this.devices = [...this.devices, foundDevice];
    this.markers = [this.devices, this.positions];

    // wait for map to update
    setTimeout(() => {
      this.selectDevice(foundDevice.device_id);
    }, 700);
  }

  public deselectDevice(): void {
    this.chosenDevice.next(null);
  }

  public selectPosition(id: string): void {
    const select = this.positions.find(pl => pl.id === Number(id)) || null;

    this.chosenPosition.next(select);
  }

  public deselectPosition(): void {
    this.chosenPosition.next(null);
  }

  public replaceDevices(deviceId: string, newDevice: PositionedDeviceData[]) {
    this.deselectDevice();
    const device = this.devices.find(dv => dv.device_id === deviceId);

    if (!device) {
      return;
    }

    const index = this.devices.indexOf(device);

    this.devices.splice(index, 1, newDevice[0]);
    this.markers = [this.devices, this.positions];
  }

  public async bindDevice(deviceId: string, positionId: number) {
    this.deselectDevice();

    const { devices } = await this.api.devices.getPositionedDevicesById([deviceId], this.helper);
    if (devices?.length === 1) {
      this.devices = [...this.devices, ...devices];
    } else {
      this.helper.alert('Something went wrong. Try again later');
      return;
    }

    const index = this.positions.findIndex(pos => pos.id === positionId);

    if (index >= 0) {
      this.positions.splice(index, 1);
    }

    this.markers = [this.devices, this.positions];
  }

  public async unbindDevice(deviceId: string, positionId: number) {
    this.helper.showLoading();
    this.deselectDevice();
    const position = await this.api.positions.getPosition(positionId, this.helper);

    if (position) {
      this.positions = [...this.positions, position];
    }

    const index = this.devices.findIndex(device => device.device_id === deviceId);

    if (index >= 0) {
      this.devices.splice(index, 1);
    }

    this.markers = [this.devices, this.positions];
    this.helper.hideLoading();
  }

  public async updatePosition(positionId: number) {
    this.helper.showLoading();
    this.deselectPosition();
    const position = await this.api.positions.getPosition(positionId, this.helper);
    const index = this.positions.findIndex(pos => pos.id === positionId);

    if (position) {
      this.positions.splice(index, 1, position);
    }

    this.markers = [this.devices, this.positions];
    this.helper.hideLoading();
  }

  public async updateDevice(deviceId: string) {
    this.helper.showLoading();
    this.deselectDevice();
    const { devices } = await this.api.devices.getPositionedDevicesById([deviceId], this.helper);

    if (devices?.length === 1) {
      const index = this.devices.findIndex(device => device.device_id === deviceId);

      if (index >= 0) {
        this.devices.splice(index, 1, devices[0]);
      }
    }

    this.markers = [this.devices, this.positions];
    this.helper.hideLoading();
  }

  public async deletePosition(positionId: number) {
    const index = this.positions.findIndex(pos => pos.id === positionId);
    this.positions.splice(index, 1);

    this.markers = [this.devices, this.positions];
  }

  public async groupBindDevices(devicesIds: string[], positionsIds: number[]) {
    this.helper.showLoading();
    if (devicesIds.length) {
      const { devices } = await this.api.devices.getPositionedDevicesById(devicesIds, this.helper);
      if (devices?.length) {
        this.devices = [...this.devices, ...devices];
      } else {
        this.helper.alert('Error occurred while binding devices. Please restart the application');
        this.helper.hideLoading();
        return;
      }
    }

    if (positionsIds.length) {
      const { positions } = await this.api.positions.getPositions({ position_id: positionsIds }, this.helper);

      if (positions?.length) {
        this.positions = [...this.positions, ...positions];
      } else {
        this.helper.alert('Error occurred while creating positions. Please restart the application');
        this.helper.hideLoading();
        return;
      }
    }

    this.markers = [this.devices, this.positions];
    this.helper.hideLoading();
  }

  public addNewPositions(positions: Position[]) {
    this.deselectPosition();

    this.positions = [...this.positions, ...positions];
  }

  public remove() {
    this.device = null;
    this.chosenDevice.next(null);
  }

  public async fetchZones(filters: ZonesFilters) {
    this.zonesLoading = true;
    const { zones } = await this.api.zones.fetchZones(filters);
    this.zonesLoading = false;

    if (zones && zones.length) {
      this.zones = zones.sort(sortByName);
    } else {
      this.zones = [];
    }

    if (this.preselectedValues.zoneId) {
      const check = this.zones.find(zone => zone.id === this.preselectedValues.zoneId);
      if (check) {
        this.selectZone(this.preselectedValues.zoneId);
      } else {
        this.helper.alert('Selected Zone not found. Please check your account', 'Warning');
      }
    }
  }

  public async fetchLevels(filters: LevelsFilters) {
    this.levelsLoading = true;
    const { levels } = await this.api.levels.fetch(filters);
    this.levelsLoading = false;

    if (levels && levels.length) {
      this.levels = levels.sort(sortByName);
    } else {
      this.levels = [];
    }

    if (this.preselectedValues.levelId) {
      const check = this.levels.find(level => level.id === this.preselectedValues.levelId);
      if (check) {
        this.selectLevel(this.preselectedValues.levelId);
      } else {
        this.helper.alert('Selected Level not found. Please check your account', 'Warning');
      }
    }
  }

  public async fetchGroups(filters: GroupsFilters) {
    this.groupsLoading = true;
    const { groups } = await this.api.groups.fetchGroups(filters);
    this.groupsLoading = false;

    if (groups && groups.length) {
      this.groups = groups.sort(sortByName);
    } else {
      this.groups = [];
    }

    if (this.preselectedValues.groupId) {
      const check = this.groups.find(group => group.id === this.preselectedValues.groupId);
      if (check) {
        this.selectGroup(this.preselectedValues.groupId);
      } else {
        this.helper.alert('Selected Group not found. Please check your account', 'Warning');
      }
    }
  }

  public async fetchLabels() {
    this.labels = [];
    this.labelsLoading = true;
    const { labels } = await this.api.labels.fetch();
    this.labelsLoading = false;

    if (labels && labels.length) {
      this.labels = labels.sort(sortByLabelName);
    }
  }

  public preselectedValuesClear() {
    this.preselectedValues = {
      zoneId: undefined,
      groupId: undefined,
      levelId: undefined,
    };
  }

  public async updateLabels(positionId: number, currentLabels?: Label[]): Promise<boolean | void> {
    const detachingLabels: number[] = [];
    const attachingLabels: number[] = [];

    for (const label of this.selectedLabels.value) {
      if (!currentLabels || !currentLabels.some(l => l.id === label.id)) {
        attachingLabels.push(label.id);
      }
    }

    if (currentLabels) {
      for (const label of currentLabels) {
        if (!this.selectedLabels.value.some(l => l.id === label.id)) {
          detachingLabels.push(label.id);
        }
      }
    }

    if (!detachingLabels.length && !attachingLabels.length) {
      return Promise.resolve(false);
    }

    this.helper.showLoading('Updating labels...');
    if (attachingLabels.length) {
      await this.api.positions.attachLabels({
        positions: [positionId],
        labels: attachingLabels,
      }, this.helper);
    }

    if (detachingLabels.length) {
      await this.api.positions.detachLabels({
        positions: [positionId],
        labels: detachingLabels,
      }, this.helper);
    }

    this.helper.hideLoading();

    return Promise.resolve(true);
  }

  public clearListsAndSelectedData() {
    this.selectedZone.next(undefined);
    this.selectedGroup.next(undefined);
    this.selectedLabels.next([]);
    this.selectedLevel.next(undefined);
    this.deselectDevice();
    this.groups = [];
    this.zones = [];
    this.levels = [];
    this.devices = [];
    this.positions = [];
    this.markers = [[], []];
  }
}
