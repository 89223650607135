import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { Amplify, Auth } from 'aws-amplify';

import amplify from './aws-exports';
import { LocaLstorageService } from './app/store/localstorage.service';
import { Storage } from '@ionic/storage-angular';
import { DmStoreService } from './app/store/dm-store.service';
import { BarcodeScanProvider, HelperProvider } from './app/providers';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),
    provideRouter(routes),
    LocaLstorageService,
    Storage,
    DmStoreService,
    HelperProvider,
    BarcodeScanProvider,
    {
      provide: ErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      multi: true,
    },
  ],
});

defineCustomElements(window);

const APIoptions = {
  API: {
    endpoints: environment.endpoints.map(e => ({
      name: e.endpointName,
      endpoint: e.endpoint,
      custom_header: async () => {
        /**
         * for cognito login param:
         * 'X-Api-Key': (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken
         */
        return {
          'X-Api-Key': (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken,
          'Content-Type': 'application/json',
        };
      },
      region: environment.region,
    })),
  },
};

Amplify.configure({ ...amplify, ...APIoptions });

