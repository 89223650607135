/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ExtendedIncidentsTypesEnumModel,
    IncidentReasonEnum,
    IncidentState,
    IncidentsTypesEnumModel,
} from './';

/**
 * Datatype for hardware error Incident
 * @export
 * @interface HardwareErrorIncident
 */
export interface HardwareErrorIncident {
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    resolving_type?: HardwareErrorIncidentResolvingTypeEnum;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    comment?: string;
    /**
     * @type {Date}
     * @memberof HardwareErrorIncident
     */
    created_time: Date;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    device_id: string;
    /**
     * @type {IncidentsTypesEnumModel | ExtendedIncidentsTypesEnumModel}
     * @memberof HardwareErrorIncident
     */
    error_type: IncidentsTypesEnumModel | ExtendedIncidentsTypesEnumModel;
    /**
     * @type {IncidentReasonEnum}
     * @memberof HardwareErrorIncident
     */
    reason?: IncidentReasonEnum;
    /**
     * @type {number}
     * @memberof HardwareErrorIncident
     */
    id?: number;
    /**
     * @type {number}
     * @memberof HardwareErrorIncident
     */
    zone_id?: number;
    /**
     * @type {number}
     * @memberof HardwareErrorIncident
     */
    group_id?: number;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    zone_name?: string;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    group_name?: string;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    network_id?: string;
    /**
     * @type {boolean}
     * @memberof HardwareErrorIncident
     */
    damaged_status?: boolean;
    /**
     * @type {boolean}
     * @memberof HardwareErrorIncident
     */
    activation_status?: boolean;
    /**
     * @type {boolean}
     * @memberof HardwareErrorIncident
     */
    is_resolved?: boolean;
    /**
     * @type {boolean}
     * @memberof HardwareErrorIncident
     */
    damaging?: boolean;
    /**
     * @type {Date}
     * @memberof HardwareErrorIncident
     */
    resolved_time?: Date;
    /**
     * @type {IncidentState}
     * @memberof HardwareErrorIncident
     */
    state: IncidentState;
    /**
     * @type {string}
     * @memberof HardwareErrorIncident
     */
    user_id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum HardwareErrorIncidentResolvingTypeEnum {
    Auto = 'auto',
    Manual = 'manual'
}

