/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    IncidentHistory,
} from './';

/**
 * Incidents statistic model
 * @export
 * @interface IncidentStatistic
 */
export interface IncidentStatistic {
    /**
     * @type {Array<IncidentHistory>}
     * @memberof IncidentStatistic
     */
    failures_history?: Array<IncidentHistory>;
    /**
     * @type {Array<IncidentHistory>}
     * @memberof IncidentStatistic
     */
    silent_history?: Array<IncidentHistory>;
    /**
     * @type {number}
     * @memberof IncidentStatistic
     */
    incidents_number?: number;
    /**
     * @type {number}
     * @memberof IncidentStatistic
     */
    not_resolved_number?: number;
    /**
     * @type {number}
     * @memberof IncidentStatistic
     */
    silent_ok?: number;
    /**
     * @type {number}
     * @memberof IncidentStatistic
     */
    total_failures?: number;
}
